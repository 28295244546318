<template>
    <v-card outlined flat tile>
        <template v-if="!!title">
            <v-card-title class="pa-2 title">
                {{ title }}
            </v-card-title>
            <v-divider />
        </template>
        <v-card-text :class="bodyClass">
            <slot></slot>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ['title', 'body-class']
}
</script>